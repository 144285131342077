.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #235688;
  margin: 0;
  padding: 0;
}

.leftPanel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 40%;
  color: #fff;
  padding: 0 70px;
  height: 100vh;
  box-sizing: border-box;
  background: linear-gradient(135deg, #1682b4, #1974a6, #205284);
}

.welcomeText {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.welcomeText h1 {
  font-size: 46px;
  margin: 10px 0;
  font-weight: 600;
}

.welcomeText p {
  font-size: 16px;
  font-weight: 500;
}

.loginButton {
  background-color: transparent;
  color: #235688;
  padding: 15px 70px;
  margin: 20px;
  border: 1px solid white;
  color: white;
  border-radius: 36px;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s;
}

.loginButton:hover {
  background: linear-gradient(135deg, #1682b4, #1974a6, #205284);
}

.rightPanel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 3;
  width: 60%;
  height: 100vh;
  box-sizing: border-box;
  background-color: white;
  padding: 0 20px;
  text-align: center;
}

.signupForm {
  max-width: 450px;
  width: 100%;
}

.heading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.heading h2 {
  font-weight: 500;
}

.heading img {
  position: relative;
  margin-left: 10px;
  width: 60px;
  height: 50px;
}

.formField {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #626262;
  margin-bottom: 10px;
}

.formField label {
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
}

.formField input {
  width: 100%;
  font-size: 16px;
  padding: 16px 20px;
  border: none;
  border-radius: 30px;
  background-color: #f0f3f6;
}

.signupButton {
  background: linear-gradient(135deg, #1682b4, #1974a6, #205284) !important;
  color: #fff;
  padding: 15px 80px;
  border: none;
  border-radius: 40px;
  font-weight: bold;
  cursor: pointer;
  margin: 10px;
}

.orText {
  position: relative;
  background-color: white;
  font-weight: 450;
  padding: 0px 5px;
  margin-top: 15px;
  z-index: 1;
}

.socialLogin {
  display: flex;
  justify-content: center;
}

.logo {
  display: flex;
  align-items: center;
  padding: 5px;
  margin: 5px;
  margin-top: 0px;
  width: 40px;
  border: none;
  border-radius: 50px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.logo:hover {
  background-color: #ededed;
}

.arrow {
  width: 50px;
  height: auto;
}

/* Larger than 768px (desktop) */
@media (max-width: 768px) {
  .container {
    flex-direction: row;
  }

  .leftPanel {
    width: 20%;
    padding: 0 70px;
  }

  .welcomeText {
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .welcomeText h1 {
    font-size: 20px;
  }

  .welcomeText p {
    font-size: 14px;
  }

  .loginButton {
    padding: 10px 50px;
    margin: 20px;
  }

  .rightPanel {
    width: 80%;
    padding: 0 20px;
  }

  .signupForm {
    max-width: 400px;
    width: 100%;
  }

  .heading {
    display: flex;
    justify-content: center;
  }

  .formField input {
    font-size: 14px;
    padding: 16px 20px;
  }

  .signupButton {
    padding: 10px 60px;
  }
}

/* Between 540px and 768px (tablet) */
@media (min-width: 540px) and (max-width: 767px) {
  .leftPanel {
    width: 20%;
    padding: 0 40px;
  }

  .welcomeText {
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .welcomeText h1 {
    font-size: 18px;
  }

  .welcomeText p {
    font-size: 12px;
  }

  .loginButton {
    padding: 8px 40px;
  }

  .rightPanel {
    width: 80%;
    padding: 0 15px;
  }

  .signupForm {
    max-width: 350px;
    width: 100%;
  }

  .formField input {
    font-size: 12px;
    padding: 16px 18px;
  }

  .signupButton {
    padding: 10px 50px;
  }
}

/* Between 425px and 539px */
@media (min-width: 425px) and (max-width: 539px) {
  .leftPanel {
    width: 20%;
    padding: 0 40px;
  }

  .welcomeText {
    white-space: wrap;
    text-overflow: ellipsis;
  }

  .welcomeText h1 {
    font-size: 15px;
  }

  .welcomeText p {
    font-size: 10px;
  }

  .loginButton {
    padding: 2px 30px;
    font-size: 12px;
    font-weight: 500;
  }

  .rightPanel {
    width: 80%;
    padding: 0 15px;
  }

  .signupForm {
    max-width: 300px;
    width: 100%;
  }

  .formField label {
    font-size: 10px;
  }

  .formField input {
    font-size: 12px;
    padding: 10px 14px;
  }

  .signupButton {
    padding: 6px 40px;
    font-size: 12px;
    font-weight: 500;
  }

  .orText {
    font-size: 14px;
  }

  .arrow {
    width: 40px;
  }
}

/* Smaller than 375px (mobile) */
@media (max-width: 375px) {
  .leftPanel {
    width: 15%;
    padding: 0 40px;
  }

  .welcomeText {
    white-space: wrap;
    text-overflow: ellipsis;
  }

  .welcomeText h1 {
    font-size: 12px;
  }

  .welcomeText p {
    font-size: 10px;
  }

  .loginButton {
    padding: 2px 30px;
    font-size: 12px;
    font-weight: 500;
  }

  .rightPanel {
    width: 85%;
    padding: 0 15px;
  }

  .heading h2 {
    font-size: 12px;
  }
  .signupForm {
    max-width: 300px;
    width: 100%;
  }

  .formField label {
    font-size: 10px;
  }

  .formField input {
    font-size: 12px;
    padding: 10px 14px;
  }

  .signupButton {
    padding: 6px 40px;
    font-size: 12px;
    font-weight: 500;
  }

  .orText {
    font-size: 14px;
  }

  .arrow {
    width: 40px;
  }

  /* Smaller than 320px (mobile) */
  @media (max-width: 320px) {
    .leftPanel {
      width: 15%;
      padding: 0 40px;
    }

    .welcomeText {
      white-space: wrap;
      text-overflow: ellipsis;
    }

    .welcomeText h1 {
      font-size: 10px;
    }

    .welcomeText p {
      font-size: 6px;
      margin: 10px;
    }

    .loginButton {
      padding: 2px 20px;
      font-size: 10px;
      font-weight: 500;
    }

    .rightPanel {
      width: 80%;
      padding: 0 15px;
    }

    .heading h2 {
      font-size: 12px;
    }

    .heading img {
      width: 40px;
      height: 35px;
    }

    .signupForm {
      max-width: 300px;
      width: 100%;
    }

    .formField label {
      font-size: 8px;
    }

    .formField input {
      font-size: 12px;
      padding: 6px 12px;
    }

    .signupButton {
      padding: 5px 30px;
      font-size: 10px;
      font-weight: 500;
    }

    .orText {
      font-size: 12px;
    }

    .arrow {
      width: 30px;
    }
  }
}
