.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  padding: 10px;
  background-color: #eff3f7;
}

.mainTitle {
  text-align: center;
  padding: 2px;
  font-size: 30px;
  font-weight: 900;
  letter-spacing: 0.06em;
  font-family: "Montserrat", sans-serif !important;
}

.content {
  text-align: center;
  /* margin-top: 5%; */
}

.content h1 {
  /* padding: 10px; */
  margin-top: 10px;
  margin-bottom: 30px;
  letter-spacing: 0.06em;
}

.cards {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
}

.card {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px;
  margin: 10px;
  width: 450px;
  height: 300px;
  text-align: left;
  border: 1px solid silver;
  border-radius: 25px;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s, color 0.2s;
}

.qrCode {
  width: 100%;
  display: block;
  margin: 5px auto;
  max-width: 50%; /* Ensures it won't exceed its natural size */
}
.card:hover {
  background-color: #235688;
  color: #fff;
}

.card > img {
  position: absolute;
  top: 10px;
  left: 20px;
  width: 50px;
}

.card h2 {
  margin: 0;
  margin-left: 60px;
  padding: 0;
  font-size: 20px;
  font-weight: bold;
  padding: 5px;
  flex: 0;
}

.card p {
  margin: 5px;
  padding: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  letter-spacing: 1.6px;
  flex: 1;
  overflow-y: auto;
}

.card:hover p {
  color: #eff3f7;
}

.card::before {
  content: "";
  position: absolute;
  top: 20px;
  left: 0;
  width: 5px;
  height: 85%;
  background-color: #235688;
  border-radius: 8px 0 0 8px;
}

.card:hover::before {
  background-color: white;
}

.notification-card {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px;
  margin: 10px;
  width: 100%;
  max-width: 450px;
  height: 300px;
  text-align: left;
  border: 1px solid silver;
  border-radius: 25px;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color, color;
  overflow: hidden;
}

.notification-card:hover {
  background-color: white;
}

.notification-card::before {
  content: none !important;
}

.notification-title {
  background-color: white;
  color: #235688;
  padding: 15px 10px;
  width: 110%;
  margin: -20px 1px 1px -20px;
  border-radius: 25px 25px 0 0;
  z-index: 1;
}

.notification-card:hover .notification-title {
  background-color: #235688;
  color: white;
}

.notification-card img {
  position: absolute;
  top: 14px;
  left: 10px;
  width: 50px;
  transition: filter 0.2s;
}

.notification-card:hover img {
  filter: brightness(10);
}

.notification-card h2 {
  margin: 0;
  margin-left: 60px;
  padding: 0;
  font-size: 20px;
  font-weight: bold;
  padding: 5px;
  flex: 0;
}

.notification-card p {
  animation: scroll 120s linear infinite;
  white-space: normal;
  transform: translateY(100%);
  padding: 20px 0;
  margin: 5px;
  padding: 20px;
  font-size: 17px;
  font-weight: 500;
  color: #333;
  letter-spacing: 1.6px;
  flex: 1;
  overflow-y: hidden;
}

.notification-card:hover p {
  color: black;
}

@keyframes scroll {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}

.notification-card p::-webkit-scrollbar {
  width: 4px;
}

.notification-card p::-webkit-scrollbar-thumb {
  background: #235688;
  background-size: 100% 4px;
  border-radius: 4px;
}

/* Portrait Styles for 1024px width */
@media (max-width: 1024px) {
  .mainTitle {
    font-size: 40px;
    font-weight: 700;
  }

  .cards {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .card {
    width: 450px;
    height: 300px;
    margin-right: 0;
  }

  .card p {
    font-size: 16px;
  }

  .notification-card {
    margin: 20px;
    margin-bottom: 50px;
    max-width: 920px;
    width: 100%;
    height: 300px;
  }

  .notification-card p {
    align-content: center;
    text-align: center;
    font-size: larger;
  }
}

/* Landscape Styles for 1024px width */
@media (max-width: 1024px) and (orientation: landscape) {
  .mainTitle {
    font-size: 35px;
    font-weight: 700;
  }

  .cards {
    flex-direction: column;
    margin-top: 10px;
  }

  .card {
    width: 600px;
    height: 320px;
    padding: 15px;
    margin: 10px auto;
  }

  .qrCode {
    width: 100%;
    display: block;
    margin: 5px auto;
    max-width: 40%;
  }

  .card > img {
    width: 50px;
  }

  .card h2 {
    font-size: 24px;
    margin-left: 70px;
  }

  .card p {
    font-size: 22px;
    margin: 10px;
  }

  .notification-card {
    margin: 10px auto;
    margin-bottom: 50px;
  }

  .notification-card h2 {
    margin-top: 4px;
  }

  .notification-card p {
    padding: 10px auto;
    font-size: 22px;
    text-align: center;
    align-items: center;
    margin: auto 10px;
  }
}

/* Tablets Responsive */
@media (max-width: 768px) {
  .cards {
    flex-direction: column;
    flex-wrap: wrap;
  }

  .card {
    width: 550px;
    height: 300px;
    margin-right: 0;
    padding: 10px;
  }

  .card {
    font-size: 20px;
  }

  .notification-card {
    margin: 20px;
    margin-bottom: 50px;
    width: 100%;
    max-width: 550px;
    height: 300px;
  }
}

@media (max-width: 768px) and (orientation: landscape) {
  .mainTitle {
    font-size: 30px;
    margin-top: 20px;
  }

  .cards {
    flex-direction: column;
    margin-top: 10px;
  }

  .card {
    width: 600px;
    height: 350px;
    padding: 15px;
    margin: 10px auto;
  }

  .qrCode {
    width: 100%;
    display: block;
    margin: 25px auto;
    max-width: 40%;
  }
  .card > img {
    width: 50px;
  }

  .card h2 {
    font-size: 22px;
    margin-left: 70px;
  }

  .card p {
    font-size: 22px;
    margin: 10px;
  }

  .card::before {
    width: 5px;
    height: 80%;
    top: 10%;
  }

  .notification-card {
    margin: 10px;
    margin-bottom: 50px;
    width: 100%;
    max-width: 600px;
    height: 350px;
  }

  .notification-card h2 {
    margin-top: 4px;
  }

  .notification-card p {
    padding: 10px auto;
    font-size: 22px;
    text-align: center;
    align-items: center;
    flex: 0;
    margin: auto 10px;
  }
}

/* Mobile Responsive */
@media (max-width: 576px) {
  .mainTitle {
    font-size: 14px;
  }

  .cards {
    flex-direction: column;
    align-items: center;
  }

  .cards h2 {
    font-size: 14px;
  }

  .card {
    width: 350px;
    height: 200px;
    margin: 10px 0;
  }

  .card p {
    font-size: 14px;
  }

  .notification-card {
    width: 350px;
    height: 200px;
    margin: 10px 0;
    margin-bottom: 50px;
  }
}

/* Mobile Responsive (480px) */
@media (max-width: 480px) {
  .mainTitle {
    font-size: 18px;
    margin-bottom: 0;
  }

  .cards {
    flex-direction: column;
    align-items: center;
    margin-top: 0;
  }

  .cards h2 {
    font-size: 12px;
    margin-left: 50px;
    margin-top: 3px;
  }

  .card {
    width: 400px;
    height: 200px;
    margin: 10px 0;
  }

  .qrCode {
    width: 100%;
    display: block;
    margin: 5px auto;
    max-width: 35%;
  }

  .card > img {
    width: 40px;
  }

  .card p {
    margin: 10px;
    font-size: 10px;
  }

  .notification-card {
    width: 400px;
    height: 200px;
    margin: 10px 0;
    margin-bottom: 40px;
  }

  .notification-card p {
    font-size: 14px;
  }
}

/* Portrait orientation for devices with a width of 425px */
@media (max-width: 425px) {
  .container {
    min-height: 40vh;
  }

  .mainTitle {
    font-size: 20px;
    margin: 0;
  }

  .card {
    width: 350px;
    height: 200px;
    margin: 10px 0;
  }

  .qrCode {
    width: 100%;
    display: block;
    margin: 5px auto;
    max-width: 40%;
  }

  .card > img {
    width: 40px;
  }

  .card p {
    margin: 10px;
    font-size: 10px;
  }

  .notification-card {
    width: 350px;
    height: 200px;
    margin: 10px 0;
    margin-bottom: 40px;
  }

  .notification-card p {
    font-size: 12px;
  }

  .notification-title > img {
    width: 40px;
  }
  .notification-title > h2 {
    margin-top: 12px;
  }
}

/* Landscape orientation for devices with a width of 425px */
@media (max-width: 425px) and (orientation: landscape) {
  .mainTitle {
    font-size: 16px;
  }
  .container {
    min-height: 40vh;
  }
  .cards {
    margin: 0;
  }
  .card {
    width: 350px;
    height: 180px;
    margin: 10px auto;
  }

  .card > img {
    width: 30px;
  }
  .card h2 {
    font-size: 12px;
    padding: 0;
  }
  .card p {
    margin: 0px;
    font-size: 10px;
    font-weight: 500;
  }
  .card::before {
    width: 2px;
    height: 80%;
  }
  .notification-card {
    width: 350px;
    height: 180px;
    margin: 10px 0;
    margin-bottom: 40px;
  }
  .notification-card img {
    width: 30px;
  }
  .notification-card h2 {
    font-size: 12px;
    padding: 0;
  }
  .notification-card p {
    margin: 0px;
    font-size: 12px;
    font-weight: 500;
  }

  .notification-title > img {
    width: 40px;
  }
  .notification-title > h2 {
    margin-top: 12px;
  }
}

/* Mobile Responsive (375px) */
@media (max-width: 375px) {
  .mainTitle {
    font-size: 16px;
  }

  .cards {
    flex-direction: column;
    align-items: center;
    margin-top: 0;
  }

  .cards h2 {
    font-size: 12px;
    margin-left: 35px;
    padding: 0;
    margin-top: 4px;
    margin-left: 45px;
  }

  .card {
    width: 300px;
    height: 160px;
    margin: 10px auto;
  }

  .card > img {
    width: 25px;
  }

  .card p {
    margin: 2px;
    font-size: 8px;
    font-weight: 600;
  }

  .card::before {
    width: 3px;
    height: 70%;
  }

  .notification-card {
    width: 300px;
    height: 160px;
    margin: 10px 0;
    margin-bottom: 40px;
  }

  .notification-card img {
    width: 25px;
  }

  .notification-card h2 {
    font-size: 10px;
    padding: 0;
    margin-top: 10px;
    margin-left: 50px;
  }

  .notification-card p {
    margin: 0px;
    font-size: 10px;
    font-weight: 500;
  }
  .notification-title > img {
    width: 40px;
  }
  .notification-title > h2 {
    margin-top: 12px;
  }
}

/* Mobile Responsive (320px) */
@media (max-width: 320px) {
  .mainTitle {
    font-size: 16px;
    margin: 0px;
    padding: 0;
  }

  .cards {
    flex-direction: column;
    align-items: center;
    margin-top: -20px;
  }

  .cards h2 {
    font-size: 10px;
    margin-left: 40px;
    margin-top: 4px;
    padding: 0;
  }

  .card {
    width: 280px;
    height: 180px;
    margin: 10 px auto;
  }

  .qrCode {
    width: 100%;
    display: block;
    margin: 10px auto;
    max-width: 200px;
  }

  .card > img {
    width: 20px;
  }
  .qrCode {
    width: 40%;
  }

  .card p {
    margin: 0px;
    font-size: 9px;
    font-weight: 500;
    line-height: 1.5;
  }

  .card::before {
    width: 1px;
    height: 70%;
  }

  .notification-card {
    width: 280px;
    height: 160px;
    margin: 10px 0;
    margin-bottom: 40px;
  }

  .notification-card img {
    width: 20px;
  }

  .notification-card h2 {
    font-size: 10px;
    margin-left: 45px;
    margin-top: 8px;
    padding: 0;
  }

  .notification-card p {
    font-size: 8px;
    font-weight: 500;
  }

  .notification-title > img {
    width: 30px;
  }
  .notification-title > h2 {
    margin-top: 15px;
  }
}

@media (max-width: 320px) and (orientation: landscape) {
  .mainTitle {
    font-size: 12px;
    margin-top: 5px;
  }

  .cards {
    flex-direction: column;
    margin-top: 0px;
  }

  .card {
    width: 90%;
    height: 180px;
    padding: 10px;
    margin: 10px;
  }

  .card > img {
    width: 20px;
  }

  .card h2 {
    font-size: 10px;
    margin-left: 40px;
    margin-top: 4px;
  }

  .card p {
    font-size: 9px;
    font-weight: 600;
    margin: 2px;
  }

  .card::before {
    width: 2px;
    height: 70%;
  }

  .notification-card {
    width: 90%;
    height: 180px;
    margin: 10px 0;
    margin-bottom: 40px;
  }

  .notification-card img {
    width: 20px;
  }

  .notification-card h2 {
    font-size: 10px;
    margin-left: 48px;
    margin-top: 8px;
  }

  .notification-card p {
    margin: 2px;
    font-size: 9px;
    font-weight: 600;
  }

  .notification-title > img {
    width: 30px;
  }
  .notification-title > h2 {
    margin-top: 15px;
  }
}
