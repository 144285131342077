body {
  margin: 0;
}

.pdf-container {
  text-align: center;
  height: 100%;
  width: 100%;
  padding: 10%;
}

.pdf-preview {
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;
}

.pdf-background {
  width: 100%;
  height: 100%;
}

.pdf-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  max-width: 80%;
  max-height: 70%;
}

.pdf-text {
  margin-top: 400px;
  font-size: 26px;
  font-weight: bold;
  line-height: 4;
  text-align: center;
  color: #393087;
}

.pdf-details {
  margin-top: 100px;
  font-size: 22px;
  font-weight: bold;
  line-height: 2;
  text-align: left;
  color: #393087;
  line-height: 3;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
    1px 1px 0 #fff;
}

.user-info {
  color: red;
}

@media only screen and (max-width: 1025px) {
  .pdf-text {
    font-size: 18px;
    font-weight: bold;
    line-height: 2;
    margin-top: 400px;
  }

  .pdf-details {
    font-size: 18px;
    line-height: 2;
    margin-top: 50px;
  }
}

@media only screen and (max-width: 768px) {
  .pdf-text {
    font-size: 10px;
    font-weight: bold;
    line-height: 2;
    margin-top: 50%;
    text-align: center;
  }

  .pdf-details {
    font-size: 10px;
    font-weight: bold;
    text-align: left;
    line-height: 2;
    margin-top: 8%;
  }
}

@media only screen and (max-width: 426px) {
  .pdf-text {
    font-size: 10px;
    line-height: 2;
    text-align: center;
    margin-top: 45%;
  }

  .pdf-details {
    font-size: 10px;
    line-height: 2;
    text-align: left;
    margin-top: 3%;
  }
}

@media only screen and (max-width: 376px) {
  .pdf-text {
    font-size: 10px;
    text-align: center;
    margin-top: 35%;
  }

  .pdf-details {
    font-size: 10px;
    text-align: left;
    margin-top: 5%;
  }
}

@media only screen and (max-width: 320px) {
  .pdf-text {
    font-size: 8px;
    margin-top: 35%;
    text-align: center;
  }

  .pdf-details {
    font-size: 8px;
    margin-top: 5%;
  }
}
