.footer {
  background-color: #d0d0d0;
  height: auto;
  padding: 20px;
}

.adminLogin {
  padding: 20px;
  margin: 20px;
  margin-left: 60px;
  max-width: 300px;
  text-align: center;
}

.adminLogin h3 {
  color: #515151;
  font-size: 20px;
  text-align: left;
}

.adminLogin form {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.adminLogin input {
  margin: 10px 0;
  padding: 15px;
  width: 200%;
  background-color: #e4e4e4;
  border: none;
  border-radius: 4px;
}

.adminLogin button {
  background: #235688;
  color: #fff;
  margin-top: 20px;
  padding: 15px;
  width: 215%;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.adminLogin button:hover {
  background: #1a4568;
}

.error {
  color: red;
  font-size: 14px;
}

.footerContent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerInfoAndLogos {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-weight: 500;
}

.logo {
  width: 100%;
  height: 100%;
  max-width: 100px;
  padding: 10px;
}

.info p {
  font-size: 12px;
}

.info span {
  color: #235688;
  font-weight: 550;
}

.footerLogos {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.footerLogos > img {
  aspect-ratio: 3/2;
  object-fit: contain;
  width: 100%;
  max-width: 140px;
  margin: 30px;
}

/* Portrait Styles for 1024px width */
@media (max-width: 1024px) {
  .footer {
    padding: 10px;
  }

  .adminLogin {
    padding: 0px;
    margin: 40px;
    margin-left: -160px;
    max-width: 300px;
    text-align: center;
  }

  .adminLogin h3 {
    font-size: 20px;
    text-align: left;
  }

  .adminLogin input {
    margin: 10px 0;
    padding: 15px;
    width: 200%;
  }

  .adminLogin button {
    padding: 15px;
    width: 215%;
  }

  .footerContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px auto;
  }

  .footerLogos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .logo {
    max-width: 100px;
    margin: 10px;
    padding: 10px;
  }

  .footerInfoAndLogos {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }

  .info p {
    font-size: 12px;
  }
}

/* Landscape Styles for 1024px width */
/* @media (max-width: 1024px) and (orientation: landscape) {
  .footer {
    padding: 20px;
  }

  .adminLogin {
    padding: 20px;
    margin: 20px;
    margin-left: -150px;
    max-width: 300px;
    text-align: center;
  }

  .adminLogin h3 {
    font-size: 20px;
    text-align: left;
  }

  .adminLogin input {
    margin: 10px 0;
    padding: 15px;
    width: 200%;
  }

  .adminLogin button {
    margin-top: 20px;
    padding: 15px;
    width: 215%;
  }

  .footerContent {
    display: flex;
  }

  .footerLogos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .footerLogos img {
    width: 100%;
    max-width: 120px;
  }

  .footerInfoAndLogos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 600px;
    height: auto;
  }

  .info {
    text-align: center;
    font-weight: 500;
    margin: 0;
  }

  .info img {
    margin-left: 50px;
  }
  .info p {
    font-size: 12px;
  }
} */

/* Tablets Responsive */
@media screen and (max-width: 768px) {
  .footer {
    height: auto;
  }

  .adminLogin {
    padding: 10px;
    margin: 10px;
    margin-left: 0;
    max-width: 100%;
    text-align: center;
  }

  .adminLogin h3 {
    font-size: 14px;
    text-align: center;
  }

  .adminLogin input {
    margin: 5px 0;
    padding: 10px;
    width: 100%;
    background-color: #e4e4e4;
    border: none;
    border-radius: 4px;
  }

  .adminLogin button {
    background: #235688;
    color: #fff;
    margin-top: 10px;
    padding: 10px;
    width: 110%;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .adminLogin button:hover {
    background: #1a4568;
  }

  .footerContent {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footerLogos {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
  }

  .logo {
    max-width: 100px;
    margin: 10px;
    padding: 5px;
  }

  .footerInfoAndLogos {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px;
  }

  .info p {
    font-size: 10px;
  }
}

/* @media (max-width: 768px) and (orientation: landscape) {
  .footer {
    height: auto;
  }

  .adminLogin {
    padding: 10px;
    margin: 10px;
    margin-left: 0;
    max-width: 100%;
    text-align: center;
  }

  .adminLogin h3 {
    font-size: 12px;
    text-align: center;
  }

  .adminLogin input {
    margin: 5px 0;
    padding: 10px;
    width: 100%;
    background-color: #e4e4e4;
    border: none;
    border-radius: 4px;
  }

  .adminLogin button {
    background: #235688;
    color: #fff;
    margin-top: 10px;
    padding: 10px;
    width: 110%;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .adminLogin button:hover {
    background: #1a4568;
  }

  .footerContent {
    flex-direction: column;
    align-items: center;
    margin: 0;
  }

  .footerLogos {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0;
  }

  .logo {
    max-width: 80px;
    margin: 10px;
    padding: 5px;
  }

  .footerInfoAndLogos {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
  }

  .info {
    text-align: center;
    padding: 10px;
    margin-right: 0;
    font-weight: 500;
  }

  .info p {
    font-size: 10px;
  }
} */

/* Tablet Responsive */
@media screen and (max-width: 576px) {
  .adminLogin {
    padding: 10px;
    margin: 10px;
    max-width: 100%;
    text-align: center;
  }

  .adminLogin h3 {
    font-size: 14px;
    text-align: center;
  }

  .adminLogin input {
    margin: 5px 0;
    padding: 10px;
    width: 100%;
  }

  .adminLogin button {
    margin-top: 10px;
    padding: 8px;
    width: 110%;
  }

  .footerContent {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
  }

  .info p {
    font-size: 10px;
  }

  .footerInfoAndLogos {
    align-items: center;
    margin: 50px;
  }

  .footerLogos {
    flex-direction: row;
    align-items: center;
    margin-right: 0;
  }

  .footerLogos > img {
    max-width: 100px;
    margin: 20px;
  }
}

/* 425px width - Portrait */
@media (max-width: 425px) {
  .footer {
    padding: 10px;
  }

  .adminLogin {
    padding: 8px;
    margin: 8px;
    max-width: 100%;
    text-align: center;
  }

  .adminLogin h3 {
    font-size: 12px;
    text-align: center;
  }

  .adminLogin input {
    margin: 5px 0;
    padding: 6px 10px;
    width: 100%;
  }

  .adminLogin button {
    margin-top: 10px;
    padding: 8px;
    width: 110%;
  }

  .footerContent {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
  }

  .footerInfoAndLogos {
    align-items: center;
    margin: 10px;
    width: 100%;
  }

  .footerLogos {
    flex-direction: row;
    align-items: center;
    margin-right: 0;
  }

  .footerLogos > img {
    max-width: 80px;
    margin: 20px;
  }

  .info {
    text-align: center;
    padding: 10px;
    margin-right: 0;
  }

  .info > img {
    padding: 20px;
    margin: 0;
  }

  .info p {
    font-size: 8px;
  }
}

/* 425px width - Landscape */
/* @media (max-width: 425px) and (orientation: landscape) {
  .footer {
    padding: 10px;
  }

  .adminLogin {
    padding: 8px;
    margin: 8px;
    max-width: 100%;
    text-align: center;
  }

  .adminLogin h3 {
    font-size: 12px;
    text-align: center;
  }

  .adminLogin input {
    margin: 5px 0;
    padding: 6px 10px;
    width: 100%;
  }

  .adminLogin button {
    margin-top: 10px;
    padding: 8px;
    width: 110%;
  }

  .footerContent {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footerLogos {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-right: 0;
  }

  .logo {
    max-width: 60px;
    margin: 10px;
    padding: 5px;
  }

  .footerInfoAndLogos {
    align-items: center;
    margin: 0;
  }

  .info {
    text-align: center;
    padding: 10px;
    margin-right: 0;
  }

  .info img {
    margin-left: 55px;
  }

  .info p {
    font-size: 10px;
  }
} */

/* Mobile Responsive (320px) */
@media screen and (max-width: 320px) {
  .adminLogin {
    padding: 8px;
    margin: 8px;
    max-width: 100%;
    text-align: center;
  }

  .adminLogin h3 {
    font-size: 12px;
    text-align: center;
  }

  .adminLogin input {
    margin: 5px 0;
    padding: 6px 10px;
    width: 100%;
  }

  .adminLogin button {
    margin-top: 10px;
    padding: 8px;
    width: 110%;
  }

  .footerContent {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footerInfoAndLogos {
    align-items: center;
    margin: 10px;
    width: 100%;
  }

  .footerLogos {
    flex-direction: row;
    align-items: center;
    margin-right: 0;
  }

  .footerLogos > img {
    max-width: 80px;
    margin: 20px;
  }

  .info {
    text-align: center;
    padding: 10px;
    margin-right: 0;
  }

  .info > img {
    padding: 20px;
    margin: 0;
  }

  .info p {
    font-size: 8px;
  }
}

@media (max-width: 320px) and (orientation: landscape) {
  .adminLogin {
    padding: 6px;
    margin: 8px;
    max-width: 100%;
    text-align: center;
  }

  .adminLogin h3 {
    font-size: 12px;
    text-align: center;
  }

  .adminLogin input {
    margin: 5px 0;
    padding: 6px 10px;
    width: 100%;
  }

  .adminLogin button {
    margin-top: 10px;
    padding: 6px;
    width: 110%;
  }

  .footerContent {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footerInfoAndLogos {
    align-items: center;
    margin: 10px;
    width: 100%;
  }

  .footerLogos {
    flex-direction: row;
    align-items: center;
    margin-right: 0;
  }

  .footerLogos > img {
    max-width: 80px;
    margin: 20px;
  }

  .info {
    text-align: center;
    padding: 10px;
    margin-right: 0;
  }

  .info > img {
    padding: 20px;
    margin: 0;
  }

  .info p {
    font-size: 8px;
  }
}
