@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700&display=swap");

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
  html {
    font-family: "Montserrat", sans-serif;
  }
}

@layer components {
  html {
    font-family: "Montserrat", sans-serif;
  }
}

@layer utilities {
  html {
    font-family: "Montserrat", sans-serif;
  }
}

/* body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Montserrat", sans-serif;
} */
