.container {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  width: 100%;
  height: 100%;
  min-height: 100vh;
  /* background-color: #1682B4; */
  margin: 0;
  padding: 0;
}
.leftPanel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  color: #fff;
  padding: 0 70px;
  height: 100vh;
  box-sizing: border-box;
  background: #1682B4;
}
.welcomeText {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.welcomeText h1 {
  font-size: 42px;
  margin: 10px 0;
  font-weight: 600;
}
.welcomeText p {
  font-size: 14px;
  font-weight: 500;
}
.loginButton {
  background-color: transparent;
  color: #235688;
  padding: 15px 70px;
  margin: 20px;
  border: 1px solid white;
  color: white;
  border-radius: 36px;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s;
}
.loginButton:hover {
  background: linear-gradient(135deg, #1682B4, #1974A6, #205284);
}
.rightPanel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 3;
  width: 60%;
  height: 100%;
  box-sizing: border-box;
  background-color: white;
  padding: 0 20px;
  text-align: center;
}
.signupForm {
  max-width: 450px;
  width: 100%;
}
.heading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.heading h2 {
  font-weight: 500;
}
.heading img {
  margin-top:15px;
  position: relative;
  /* margin-left: 2px; */
  width: 40px;
  height: auto;
}
.formField {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #626262;
  margin-bottom: 10px;
}
.formField label {
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
}
.formField input {
  width: 100%;
  font-size: 16px;
  padding: 16px 20px;
  border: none;
  /* border-radius: 30px; */
  background-color: #F0F3F6;
}
.checkbox label{
margin-left:10px;
}
.signupButton {
  background: linear-gradient(135deg, #1682B4, #1974A6, #205284) !important;
  color: #fff;
  padding: 15px 80px;
  border: none;
  /* border-radius: 40px; */
  font-weight: bold;
  cursor: pointer;
  margin: 10px;
}
.orText {
  position: relative;
  background-color: white;
  font-weight: 450;
  padding: 0px 5px;
  margin-top: 15px;
  z-index: 1;
}
.socialLogin {
  display: flex;
  justify-content: center;
}
.logo {
  display: flex;
  align-items: center;
  padding: 5px;
  margin: 5px;
  margin-top: 0px;
  width: 40px;
  border: none;
  border-radius: 50px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}
.logo:hover {
  background-color: #EDEDED;
}
.arrow {
  width: 50px;
  height: auto;
}
/* Larger than 768px (desktop) */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }
  .leftPanel,
  .rightPanel {
    width: 100%;
    padding: 0 20px;
    height: 100%;
  }
  .welcomeText {
    white-space: normal;
    text-overflow: initial;
  }
  .welcomeText h1 {
    font-size: 20px;
  }
  .welcomeText p {
    font-size: 14px;
  }
  .loginButton {
    padding: 10px 50px;
    margin: 20px 0;
  }
  .signupButton {
    padding: 10px 60px;
  }
  .rightPanel {
    margin-top: 20px;
  }
}
/* Between 540px and 768px (tablet) */
@media (min-width: 540px) and (max-width: 767px) {
  .leftPanel,
  .rightPanel {
    width: 100%;
    padding: 0 15px;
  }
  .welcomeText h1 {
    font-size: 18px;
  }
  .welcomeText p {
    font-size: 12px;
  }
  .loginButton {
    padding: 8px 40px;
  }
  .signupButton {
    padding: 10px 50px;
  }
}
/* Between 425px and 539px */
@media (min-width: 425px) and (max-width: 539px) {
  .leftPanel,
  .rightPanel {
    width: 100%;
    padding: 0 15px;
  }
  .welcomeText h1 {
    font-size: 15px;
  }
  .welcomeText p {
    font-size: 10px;
  }
  .loginButton {
    padding: 2px 30px;
    font-size: 12px;
    font-weight: 500;
  }
  .signupButton {
    padding: 6px 40px;
  }
  .formField input {
    /* display:flex;
    justify-content:center; */
    margin:auto;
    width: 80%;
    padding: 10px 14px;
  }
  .formField label{
    margin-left:50px
  }
  .orText {
    font-size: 14px;
  }
  .arrow {
    width: 40px;
  }
}
/* Between 375px and 425px (mobile) */
@media (min-width: 375px) and (max-width: 425px) {
  .leftPanel,
  .rightPanel {
    width: 100%;
    padding: 0 15px;
  }
  .welcomeText h1 {
    font-size: 13px;
  }
  .welcomeText p {
    font-size: 10px;
  }
  .loginButton {
    padding: 2px 20px;
    font-size: 10px;
    font-weight: 500;
  }
  .signupButton {
    padding: 6px 30px;
  }
  .formField input {
    width: 100%;
    font-size: 16px;
    padding: 8px 20px;
  }
  .formField label {
    margin-left: 8px;
  }
  .orText {
    font-size: 12px;
  }
  .arrow {
    width: 30px;
  }
  .rememberMeContainer {
    margin-left: -80px;
  }
  .checkbox input{
    margin-left:-110px;
    }
}
/* Smaller than 375px (mobile) */
@media (max-width: 375px) {
  .leftPanel,
  .rightPanel {
    width: 100%;
    padding: 0 15px;
  }
  .welcomeText h1 {
    font-size: 12px;
  }
  .welcomeText p {
    font-size: 10px;
  }
  .loginButton {
    padding: 2px 30px;
    font-size: 12px;
    font-weight: 500;
  }
  .rememberMeContainer {
    margin-left:-100px;
  }
  .checkbox input{
    margin-left:-40px;
    }
  .signupButton {
    padding: 6px 40px;
  }
  .formField input {
    width: 100%;
    font-size: 16px;
    padding: 7px 20px;
  }
}