/* .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #235688;
  margin: 0;
  padding: 0;
}

.leftPanel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 40%;
  color: #fff;
  padding: 0 70px;
  height: 100vh;
  box-sizing: border-box;
  background: linear-gradient(135deg, #1682b4, #1974a6, #205284);
}

.welcomeText {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.welcomeText h1 {
  font-size: 46px;
  margin: 10px 0;
  font-weight: 600;
}

.welcomeText p {
  font-size: 16px;
  font-weight: 500;
}

.loginButton {
  background-color: transparent !important;
  color: #235688;
  padding: 15px 70px;
  margin: 20px;
  border: 1px solid white;
  color: white;
  border-radius: 36px;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s;
}

.loginButton:hover {
  background: linear-gradient(135deg, #1682b4, #1974a6, #205284) !important;
}

.rightPanel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 3;
  width: 60%;
  height: 100vh;
  box-sizing: border-box;
  background-color: white;
  padding: 0 20px;
  text-align: center;
}

.signupForm {
  max-width: 450px;
  width: 100%;
}

.heading {
  display: flex;
  justify-content: center;
}

.heading img {
  position: relative;
  margin-left: 10px;
  width: 60px;
  height: 50px;
}

.formField {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #626262;
  margin-bottom: 10px;
}

.formField label {
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
}

.formField input {
  width: 100%;
  padding: 16px 20px;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  background-color: #e0e7ee;
}

.formField .phoneInput {
  width: 100%;
  padding: 18px 20px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  background-color: #e0e7ee;
}

.passwordField label {
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
  text-align: left;
}

.passwordField input {
  width: 100%;
  padding: 16px 20px;
  margin-bottom: 20px;
  border: none;
  font-size: 16px;
  background-color: #e0e7ee;
}

.signupButton {
  background: linear-gradient(135deg, #1682b4, #1974a6, #205284) !important;
  color: #fff;
  padding: 15px 80px;
  border: none;
  border-radius: 40px;
  font-weight: bold;
  cursor: pointer;
  margin: 10px;
}

.setPasswordBUtton {
  padding: 15px 220px;
  margin-top: 30px;
  white-space: nowrap;
  font-weight: bold;
  cursor: pointer;
  border: none;
  color: #fff;
  background: linear-gradient(135deg, #1682b4, #1974a6, #205284) !important;
}

.orText {
  position: relative;
  background-color: white;
  padding: 0px 5px;
  margin-top: 15px;
  z-index: 1;
}

.socialLogin {
  display: flex;
  justify-content: center;
}

.logo {
  display: flex;
  align-items: center;
  padding: 5px;
  margin: 5px;
  margin-top: 0px;
  width: 40px;
  border: none;
  border-radius: 50px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.logo:hover {
  background-color: #ededed;
}

.arrow {
  width: 50px;
  height: auto;
}

.otpContainer {
  display: flex;
  flex-direction: column;
}

.leftSection {
  display: flex;
  justify-content: flex-start;
  text-align: left;
  flex: 1;
}

.leftSection p {
  font-weight: 500;
  color: #626262;
}

.otpInputContainer {
  display: flex;
  margin-top: 10px;
}

.otpInputContainer input {
  width: 55px;
  height: 55px;
  text-align: center;
  font-size: 20px;
  border: none;
  background-color: #e0e7ee;
  border-radius: 50%;
  margin: 0 11px;
}

.otpInput {
  display: flex;
  flex-direction: row;
}

.otpInput input {
  width: 55px;
  height: 55px;
  text-align: center;
  font-size: 20px;
  border: none;
  background-color: #e0e7ee;
  border-radius: 10px;
  margin: 10px 11px;
}

.rightSection {
  display: flex;
  justify-content: flex-end;
}

.rightSection p {
  margin-right: -40px;
  color: #626262;
} */


.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}
.leftPanel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  color: #fff;
  padding: 0 70px;
  height: 100vh;
  box-sizing: border-box;
  background: #1682B4;
}
.welcomeText {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.welcomeText h1 {
  font-size: 42px;
  margin: 10px 0;
  font-weight: 600;
}
.welcomeText p {
  font-size: 14px;
  font-weight: 500;
}
.loginButton {
  background-color: transparent;
  color: #235688;
  padding: 15px 70px;
  margin: 20px;
  border: 1px solid white;
  color: white;
  border-radius: 36px;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s;
}
.loginButton:hover {
  background: linear-gradient(135deg, #1682B4, #1974A6, #205284);
}
.rightPanel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 3;
  width: 60%;
  height: 100%;
  box-sizing: border-box;
  background-color: white;
  padding: 0 20px;
  text-align: center;
}
.signupForm {
  max-width: 450px;
  width: 100%;
}
.heading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.heading img {
  margin-top: 15px;
  position: relative;
  width: 40px;
  height: auto;
}
.formField {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #626262;
  margin-bottom: 10px;
}
.formField label {
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
}
.formField input {
  width: 100%;
  font-size: 16px;
  padding: 16px 20px;
  border: none;
  background-color: #F0F3F6;
}
.formField .phoneInput {
  width: 100%;
  padding: 18px 20px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  background-color: #E0E7EE;
}
.passwordField label {
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
  text-align: left;
}
.passwordField input {
  width: 100%;
  padding: 16px 20px;
  margin-bottom: 20px;
  border: none;
  font-size: 16px;
  background-color: #E0E7EE;
}
.signupButton {
  background: linear-gradient(135deg, #1682B4, #1974A6, #205284) !important;
  color: #fff;
  padding: 15px 80px;
  border: none;
  border-radius: 40px;
  font-weight: bold;
  cursor: pointer;
  margin: 10px;
}
.setPasswordBUtton {
  padding: 15px 120px;
  margin-top: 30px;
  white-space: nowrap;
  font-weight: bold;
  cursor: pointer;
  border: none;
  color: #fff;
  background: linear-gradient(135deg, #1682B4, #1974A6, #205284) !important;
}
.orText {
  position: relative;
  background-color: white;
  padding: 0px 5px;
  margin-top: 15px;
  z-index: 1;
}
.socialLogin {
  display: flex;
  justify-content: center;
}
.logo {
  display: flex;
  align-items: center;
  padding: 5px;
  margin: 5px;
  margin-top: 0px;
  width: 40px;
  border: none;
  border-radius: 50px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}
.logo:hover {
  background-color: #EDEDED;
}
.arrow {
  width: 50px;
  height: auto;
}
.otpContainer {
  display: flex;
  flex-direction: column;
}
.leftSection {
  display: flex;
  justify-content: flex-start;
  text-align: left;
  flex: 1;
}
.leftSection p {
  font-weight: 500;
  color: #626262;
}
.otpInputContainer {
  display: flex;
  margin-top: 10px;
}
.otpInputContainer input {
  width: 55px;
  height: 55px;
  text-align: center;
  font-size: 20px;
  border: none;
  background-color: #E0E7EE;
  border-radius: 50%;
  margin: 0 11px;
}
.otpInput {
  display: flex;
  flex-direction: row;
}
.otpInput input {
  width: 55px;
  height: 55px;
  text-align: center;
  font-size: 20px;
  border: none;
  background-color: #E0E7EE;
  border-radius: 10px;
  margin: 10px 11px;
}
.rightSection {
  display: flex;
  justify-content: flex-end;
}
.rightSection p {
  margin-right: -40px;
  color: #626262;
}
/* Larger than 768px (desktop) */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }
  .leftPanel,
  .rightPanel {
    width: 100%;
    padding: 0 20px;
    height: 100%;
  }
  .welcomeText {
    white-space: normal;
    text-overflow: initial;
  }
  .welcomeText h1 {
    font-size: 20px;
  }
  .welcomeText p {
    font-size: 14px;
  }
  .loginButton {
    padding: 10px 50px;
    margin: 20px 0;
  }
  .signupButton {
    padding: 10px 60px;
  }
  .rightPanel {
    margin-top: 20px;
  }
}
/* Between 540px and 768px (tablet) */
@media (min-width: 540px) and (max-width: 767px) {
  .leftPanel,
  .rightPanel {
    width: 100%;
    padding: 0 15px;
  }
  .welcomeText h1 {
    font-size: 18px;
  }
  .welcomeText p {
    font-size: 12px;
  }
  .loginButton {
    padding: 8px 40px;
  }
  .signupButton {
    padding: 10px 50px;
  }
}
/* Between 540px and 768px (tablet) */
@media (min-width: 540px) and (max-width: 767px) {
  .leftPanel,
  .rightPanel {
    width: 100%;
    padding: 0 15px;
  }
  .welcomeText h1 {
    font-size: 18px;
  }
  .welcomeText p {
    font-size: 12px;
  }
  .loginButton {
    padding: 8px 40px;
  }
  .signupButton {
    padding: 10px 50px;
  }
}
/* Between 425px and 539px */
@media (min-width: 425px) and (max-width: 539px) {
  .leftPanel,
  .rightPanel {
    width: 100%;
    padding: 0 15px;
  }
  .welcomeText h1 {
    font-size: 15px;
  }
  .welcomeText p {
    font-size: 10px;
  }
  .loginButton {
    padding: 2px 30px;
    font-size: 12px;
    font-weight: 500;
  }
  .signupButton {
    padding: 6px 40px;
  }
  .passwordField input {
    width: 100%;
    font-size: 16px;
    padding: 8px 20px;
  }
  .orText {
    font-size: 14px;
  }
  .arrow {
    width: 40px;
  }
}
/* Between 375px and 425px (mobile) */
@media (min-width: 375px) and (max-width: 425px) {
  .leftPanel,
  .rightPanel {
    width: 100%;
    padding: 0 15px;
  }
  .welcomeText h1 {
    font-size: 12px;
  }
  .welcomeText p {
    font-size: 10px;
  }
  .loginButton {
    padding: 2px 20px;
    font-size: 10px;
    font-weight: 500;
  }
  .signupButton {
    padding: 6px 30px;
  }
  .passwordField input {
    width: 100%;
    font-size: 16px;
    padding: 8px 20px;
  }
  .orText {
    font-size: 12px;
  }
  .arrow {
    width: 30px;
  }
  .rememberMeContainer {
    margin-left: -80px;
  }
}
/* Smaller than 375px (mobile) */
@media (max-width: 375px) {
  .leftPanel,
  .rightPanel {
    width: 100%;
    padding: 0 15px;
  }
  .welcomeText h1 {
    font-size: 12px;
  }
  .welcomeText p {
    font-size: 10px;
  }
  .loginButton {
    padding: 2px 30px;
    font-size: 12px;
    font-weight: 500;
  }
  .rememberMeContainer {
    margin-left: -100px;
  }
  .signupButton {
    padding: 6px 40px;
  }
  .passwordField input {
    width: 100%;
    font-size: 16px;
    padding: 8px 20px;
  }
.setPasswordBUtton{
padding:10px 25px;
}
}