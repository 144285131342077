.container {
  background-color: #d2dbe3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.06em;
  padding: 2rem;
}

.container h1 {
  text-align: center;
  padding: 2px;
  font-size: 30px;
  font-weight: 900;
  letter-spacing: 0.06em;
  margin-top: 2rem;
  font-family: "Montserrat", sans-serif !important;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 20px;
}

.containerWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  position: relative;
}

.imageContainer {
  width: 30%;
  margin-top: 2%;
  margin-left: 2%;
}

.imageContainer img {
  width: 100%;
  height: 100%;
}

.imageContainer1 {
  width: 70%;
  height: 30%;
  margin: 50px;
  margin-top: 70px;
  position: relative;
}

.imageContainer1 > img {
  width: 100%;
  height: 100%;
}

.imageContainer1:hover {
  box-shadow: -5px -5px 1px rgba(35, 86, 136, 0.8);
}

.textContainer {
  width: 70%;
  height: auto;
  margin: 50px;
  margin-top: 70px;
  padding: 10px;
  background-color: #fff;
  color: #000000;
  position: relative;
}

.textContainer > h2 {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}

.textContainer > p {
  margin: 15px;
  line-height: 1.5;
  text-align: left;
  font-size: 1.5rem;
  font-weight: 500;
}

.textContainer:hover {
  box-shadow: -10px -10px 1px rgba(35, 86, 136, 0.8);
}

/* Portrait Styles for 1024px width */
@media (max-width: 1024px) {
  .content {
    flex-direction: column;
  }

  .containerWrapper {
    flex-direction: column;
  }

  .imageContainer {
    width: 30%;
    height: auto;
    margin: 0;
  }

  .imageContainer1 {
    margin: 20px auto;
  }

  .textContainer {
    width: 70%;
    margin: 20px auto;
    margin-top: 50px;
    margin-bottom: 40px;
    padding: 10px;
  }

  .textContainer > h2 {
    font-size: 1.5rem;
  }

  .textContainer > p {
    margin: 10px;
    text-align: left;
    font-size: 1.4rem;
    font-weight: 500;
  }

  .textContainer:hover {
    box-shadow: -5px -5px 1px rgba(35, 86, 136, 0.8);
  }
}

/* Landscape Styles for 1024px width */
/* @media (max-width: 1024px) and (orientation: landscape) {
  .content {
    flex-direction: column;
    width: 90%;
    border: 2px solid green;
  }

  .containerWrapper {
    flex-direction: column;
  }

  .imageContainer {
    width: 40%;
    height: auto;
    margin: 1rem;
  }

  .imageContainer1 > img {
    width: 100%;
    height: 120%;
  }

  .textContainer {
    width: 100%;
    margin: 20px auto;
    margin-top: 50px;
    margin-bottom: 40px;
    padding: 10px;
  }

  .textContainer > h2 {
    font-size: 1.5rem;
  }

  .textContainer > p {
    margin: 10px;
    text-align: left;
    font-size: 1.4rem;
    font-weight: 500;
  }

  .textContainer:hover {
    box-shadow: -5px -5px 1px rgba(35, 86, 136, 0.8);
  }
} */

/* Tablets Responsive */
@media (max-width: 768px) {
  .containerWrapper {
    flex-direction: column;
  }

  .imageContainer {
    width: 30%;
    height: auto;
    margin: 0;
  }

  .imageContainer1 {
    margin: 20px auto;
  }

  .textContainer {
    width: 70%;
    margin: 20px auto;
    margin-top: 50px;
    margin-bottom: 40px;
  }

  .textContainer > h2 {
    font-size: 1.5rem;
  }

  .textContainer > p {
    margin: 20px;
    text-align: left;
    font-size: 18px;
  }
}

/* @media (max-width: 768px) and (orientation: landscape) {
  .container {
    padding: 20px;
  }

  .content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
  }

  .containerWrapper {
    flex-direction: column;
    align-items: center;
    margin: 10px;
  }

  .imageContainer {
    width: 50%;
    height: auto;
    margin: 10px;
  }

  .imageContainer img {
    max-width: 100%;
    height: auto;
  }

  .textContainer {
    width: 90%;
    height: 200px;
    margin: 0 20px;
  }

  .textContainer p {
    margin: 20px;
    font-size: 16px;
    font-weight: 450;
  }

  .textContainer:hover {
    box-shadow: -4px -4px 2px rgba(35, 86, 136, 0.8);
  }
} */

/* Mobile Responsive */
@media (max-width: 576px) {
  .container {
    padding: 1rem;
  }

  .containerWrapper {
    flex-direction: column;
  }

  .imageContainer {
    width: 30%;
    height: auto;
    margin: 0;
  }

  .imageContainer1 {
    margin: 20px auto;
  }

  .textContainer {
    width: 70%;
    margin: 20px auto;
    margin-top: 50px;
    margin-bottom: 40px;
  }

  .textContainer > h2 {
    font-size: 1.5rem;
  }

  .textContainer > p {
    margin: 20px;
    text-align: left;
    font-size: 18px;
  }
}

/* Mobile Responsive (480px) */
@media (max-width: 480px) {
  .container {
    padding: 0.3rem;
  }

  .content {
    width: 100%;
  }

  .containerWrapper {
    flex-direction: column;
  }

  .imageContainer {
    width: 30%;
    height: auto;
    margin: 0;
  }

  .imageContainer1 {
    margin: 20px auto;
  }

  .textContainer {
    width: 70%;
    margin: 20px auto;
    margin-top: 50px;
    margin-bottom: 40px;
  }

  .textContainer > h2 {
    font-size: 1.3rem;
  }

  .textContainer > p {
    margin: 10px;
    text-align: left;
    font-size: 16px;
  }
}

/* Portrait orientation for devices with a width of 425px */
@media (max-width: 425px) {
  .containerWrapper {
    flex-direction: column;
  }

  .imageContainer {
    width: 30%;
    height: auto;
    margin: 0;
  }

  .imageContainer1 {
    margin: 20px auto;
  }

  .textContainer {
    width: 70%;
    margin: 20px auto;
    margin-top: 50px;
    margin-bottom: 40px;
  }

  .textContainer > h2 {
    font-size: 1.3rem;
  }

  .textContainer > p {
    margin: 10px;
    text-align: left;
    font-size: 16px;
  }
}

/* Landscape orientation for devices with a width of 425px */
/* @media (max-width: 425px) and (orientation: landscape) {
  .container {
    padding: 10px;
  }

  .content {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px;
  }

  .containerWrapper {
    flex-direction: column;
    align-items: center;
    margin: 10px;
  }

  .imageContainer {
    width: 60%;
    height: auto;
    margin: 0;
  }

  .imageContainer img {
    max-width: 100%;
    height: auto;
  }

  .textContainer {
    width: 95%;
    height: auto;
    margin: 0 auto;
  }

  .textContainer p {
    margin: 10px;
    font-size: 14px;
    font-weight: 450;
  }

  .textContainer:hover {
    box-shadow: -3px -3px 2px rgba(35, 86, 136, 0.8);
  }
} */

/* Mobile Responsive (375px) */
@media (max-width: 375px) {
  .container {
    padding: 1rem;
  }

  .containerWrapper {
    flex-direction: column;
  }

  .imageContainer {
    width: 30%;
    height: auto;
    margin: 0;
  }

  .imageContainer1 {
    margin: 20px auto;
  }

  .textContainer {
    width: 70%;
    margin: 20px auto;
    margin-top: 50px;
    margin-bottom: 40px;
  }

  .textContainer > h2 {
    font-size: 1rem;
  }

  .textContainer > p {
    font-size: 14px;
  }
}

/* Mobile Responsive (320px) */
@media (max-width: 320px) {
  .container {
    padding: 1rem;
  }

  .container > h1 {
    font-size: 1.5rem;
  }

  .content {
    margin: 1rem;
  }

  .containerWrapper {
    flex-direction: column;
  }

  .imageContainer {
    width: 30%;
    height: auto;
    margin: 0;
  }

  .imageContainer1 {
    margin: 20px auto;
  }

  .textContainer {
    width: 70%;
    margin: 20px auto;
    margin-top: 50px;
    margin-bottom: 40px;
  }

  .textContainer > h2 {
    font-size: 0.8rem;
  }

  .textContainer > p {
    font-size: 8px;
    margin: 5px;
    font-weight: 400;
  }
}

/* @media (max-width: 320px) and (orientation: landscape) {
  .container {
    padding: 10px;
  }

  .content {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px;
  }

  .containerWrapper {
    flex-direction: column;
    align-items: center;
    margin: 10px;
  }

  .imageContainer {
    width: 80%;
    height: auto;
    margin: 0;
  }

  .imageContainer img {
    max-width: 100%;
    height: auto;
  }

  .textContainer {
    width: 95%;
    height: auto;
    margin: 0 auto;
  }

  .textContainer p {
    margin: 10px;
    font-size: 9px;
    font-weight: 450;
  }

  .textContainer:hover {
    box-shadow: -3px -3px 2px rgba(35, 86, 136, 0.8);
  }
} */
